import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NewBooks from './components/NewBooks';
import TotalBooks from './components/TotalBooks';
import NewUsers from './components/NewUsers';
import NewClubs from './components/NewClubs';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import AverageBooks from './components/AverageBooks';
import DailyUsers from './components/DailyUsers';
import Promotions from './components/Promotions';
import AuthorPromotions from './components/AuthorPromotions';
import PublisherPromotions from './components/PublisherPromotions';
import InfluencerPromotions from './components/InfluencerPromotions';
import PopUpPromotions from './components/PopUpPromotions';
import RecentBooks from './components/RecentBooks';


const DefaultDashboard = ({ t, rtl }) => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Novellic Analytics Dashboard</h3>
      </Col>
    </Row>
    <Row>
      <NewBooks />
      <TotalBooks />
      <NewUsers />
      <NewClubs />
    </Row>
    <Row>
      <AverageBooks />
      <DailyUsers dir={rtl.direction} />
    </Row>
    <Row>
      <Promotions />
      <AuthorPromotions />
      <PublisherPromotions />
      <InfluencerPromotions />
    </Row>
    <Row>
      <PopUpPromotions />
    </Row>
    <Row>
      <RecentBooks />
    </Row>
  </Container>
);

DefaultDashboard.propTypes = {
  t: PropTypes.func.isRequired,
  rtl: RTLProps.isRequired,
};



export default compose(withTranslation('common'), connect(state => ({
  rtl: state.rtl,
})))(DefaultDashboard);
