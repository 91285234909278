import React from 'react';
import { Progress } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../shared/components/Panel';

const PopUpPromotions = ({ t }) => (
  <Panel md={12}  title='Cover Reveals and Book Birthday Promotions'>
    <div className="progress-wrap progress-wrap--small progress-wrap--pink progress-wrap--label-top">
      <p>Cover Reveals</p>
      <Progress value={84}><p className="progress__label">84</p></Progress>
    </div>
    <div className="progress-wrap progress-wrap--small progress-wrap--label-top">
      <p>Book Birthdays</p>
      <Progress value={60}><p className="progress__label">121</p></Progress>
    </div>
  </Panel>
);

PopUpPromotions.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(PopUpPromotions);
